import request from '@/utils/request'
// 新闻列表
export function getNewsList(data) {
  return request({
    url: 'admin/news/list',
    method: 'post',
    data: data,
  })
}
// 新闻新增
export function addList(data) {
  return request({
    url: 'admin/news/add',
    method: 'post',
    data: data,
  })
}
// 新闻删除
export function deleteList(data) {
  return request({
    url: 'admin/news/del',
    method: 'post',
    data: data,
  })
}
// 新闻修改
export function newsUpdate(data) {
  return request({
    url: 'admin/news/update',
    method: 'post',
    data: data,
  })
}
// 新闻详情
export function newsDetail(data) {
  return request({
    url: 'admin/news/detail',
    method: 'post',
    data: data,
  })
}

// 用户列表
export function memberList(data) {
  return request({
    url: 'admin/member/list',
    method: 'post',
    data: data,
  })
}

// 用户删除
export function memberDel(data) {
  return request({
    url: 'admin/member/del',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data,
    },
  })
}

// 用户修改
export function memberUdate(data) {
  return request({
    url: 'admin/member/update',
    method: 'post',
    data: data,
  })
}

// 用户详情
export function memberDetail(data) {
  return request({
    url: 'admin/member/detail',
    method: 'post',
    data: data,
  })
}

// 用户全量列表
export function memberListAll(data) {
  return request({
    url: 'index/index/memberList',
    method: 'post',
    data: data,
  })
}

// 用户全量列表
export function memberListAllZdh(data) {
  return request({
    url: 'kor_index/index/memberList',
    method: 'post',
    data: data,
  })
}

// 仓库地址列表
export function warehouseList(data) {
  return request({
    url: 'admin/warehouse/list',
    method: 'post',
    data: data,
  })
}

// 仓库地址详情
export function warehouseDetail(data) {
  return request({
    url: 'admin/warehouse/detail',
    method: 'post',
    data: data,
  })
}

// 仓库地址修改
export function warehouseUdate(data) {
  return request({
    url: 'admin/warehouse/update',
    method: 'post',
    data: data,
  })
}

// 品牌列表
export function brandList(data) {
  return request({
    url: 'admin/brand/list',
    method: 'post',
    data: data,
  })
}

// 品牌详情
export function brandDetail(data) {
  return request({
    url: 'admin/brand/detail',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data, //ID
    },
  })
}
// 品牌添加
export function brandAdd(data) {
  return request({
    url: 'admin/brand/add',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      name: data.name, //名称
      sort: data.sort,
    },
  })
}

// 品牌修改
export function brandUpdate(data) {
  return request({
    url: 'admin/brand/update',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data.id,
      name: data.name, //名称
      sort: data.sort,
    },
  })
}

// 品牌删除
export function brandDel(data) {
  return request({
    url: 'admin/brand/del',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data.ids, //ID
    },
  })
}

// 分类列表
export function cateList(data) {
  return request({
    url: 'admin/cate/list',
    method: 'post',
    data: data,
  })
}

// 分类详情
export function cateDetail(data) {
  return request({
    url: 'admin/cate/detail',
    method: 'post',
    data: data,
  })
}
// 分类添加
export function cateAdd(data) {
  return request({
    url: 'admin/cate/add',
    method: 'post',
    data: data,
  })
}

// 分类修改
export function cateUpdate(data) {
  return request({
    url: 'admin/cate/update',
    method: 'post',
    data: data,
  })
}

// 分类删除
export function cateDel(data) {
  return request({
    url: 'admin/cate/del',
    method: 'post',
    data: data,
  })
}

//优惠券添加
export function couponAdd(data) {
  return request({
    url: 'admin/coupon/add',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      title: data.title, //名称
      money: data.money, //面值
      term_of_validity: data.term_of_validity, //有效期类型 1固定时间段 2领取后多少天有效 3永久有效
      start_time: data.start_time, //开始时间
      end_time: data.end_time, //结束时间
      days: data.days, //当有效期类型为2时设定值天数
      at_least: data.at_least, //最小金额
      count: data.count, //发放数量
      remark: data.remark, //描述
      sort: 1, //排序
    },
  })
}

// 优惠券修改
export function couponUpdate(data) {
  return request({
    url: 'admin/coupon/update',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data.id,
      name: data.name, //名称
      sort: data.sort,
    },
  })
}
// 优惠券详情
export function couponDetail(data) {
  return request({
    url: 'admin/coupon/detail',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data, //ID
    },
  })
}
// 优惠券删除
export function couponDel(data) {
  return request({
    url: 'admin/coupon/del',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data.ids, //ID
    },
  })
}

// 优惠券列表
export function couponList(data) {
  return request({
    url: 'admin/coupon/list',
    method: 'post',
    data: data,
  })
}

// 商品列表
export function goodsList(data) {
  return request({
    url: 'admin/Goods/list',
    method: 'post',
    data: data,
  })
}

// 商品详情
export function goodsDetail(data) {
  return request({
    url: 'admin/Goods/detail',
    method: 'post',
    data: data,
  })
}
// 商品添加
export function goodsAdd(data) {
  return request({
    url: 'admin/Goods/add',
    method: 'post',
    data: data,
  })
}

// 商品修改
export function goodsUpdate(data) {
  return request({
    url: 'admin/Goods/update',
    method: 'post',
    data: data,
  })
}

// 商品删除
export function goodsDel(data) {
  return request({
    url: 'admin/Goods/del',
    method: 'post',
    data: data,
  })
}

// 类型列表
export function typesList(data) {
  return request({
    url: 'admin/Types/list',
    method: 'post',
    data: data,
  })
}

//类型详情
export function typesDetail(data) {
  return request({
    url: 'admin/Types/detail',
    method: 'post',
    data: data,
  })
}
// 类型添加
export function typesAdd(data) {
  return request({
    url: 'admin/Types/add',
    method: 'post',
    data: data,
  })
}

// 类型修改
export function typesUpdate(data) {
  return request({
    url: 'admin/Types/update',
    method: 'post',
    data: data,
  })
}

// 类型删除
export function typesDel(data) {
  return request({
    url: 'admin/Types/del',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data.ids, //ID
    },
  })
}

// 包裹列表
export function orderList(data) {
  var orderListTime = Math.floor(Date.now() / 1000)
  if (data.type == 1) {
    localStorage.setItem('orderListTime_k', orderListTime)
  }
  if (data.type == 2) {
    localStorage.setItem('orderListTime_J', orderListTime)
  }
  return request({
    url: 'admin/Order/list',
    method: 'post',
    data: data,
  })
}
// 中到韩包裹列表
export function orderListZdh(data) {
  var korOrderListTime = Math.floor(Date.now() / 1000)
  localStorage.setItem('kor_order_list_time', korOrderListTime)
  return request({
    url: 'kor_admin/Order/list',
    method: 'post',
    data: data,
  })
}

// 包裹状态修改（待打包，待发货，确认签收 审核拒绝）
export function orderStatus(data) {
  return request({
    url: 'admin/Order/status',
    method: 'post',
    data: data,
  })
}
// 中到韩包裹状态修改（待打包，待发货，确认签收 审核拒绝）
export function orderStatusZdh(data) {
  return request({
    url: 'kor_admin/Order/status',
    method: 'post',
    data: data,
  })
}

// 包裹详情
export function orderDetail(data) {
  return request({
    url: 'admin/Order/detail',
    method: 'post',
    data: data,
  })
}

// 中到韩包裹详情
export function orderDetailZdh(data) {
  return request({
    url: 'kor_admin/Order/detail',
    method: 'post',
    data: data,
  })
}

// 包裹修改、新增 修改传id 添加不传id
export function orderUpdate(data) {
  return request({
    url: 'admin/Order/update',
    method: 'post',
    data: data,
  })
}

// 中到韩包裹修改、新增 修改传id 添加不传id
export function orderUpdateZdh(data) {
  return request({
    url: 'kor_admin/Order/update',
    method: 'post',
    data: data,
  })
}

// 包裹商品修改
export function orderBrandUpdate(data) {
  return request({
    url: 'admin/Order/order_brand',
    method: 'post',
    data: data,
  })
}

// 中到韩包裹商品修改
export function orderBrandUpdateZdh(data) {
  return request({
    url: 'kor_admin/Order/order_brand',
    method: 'post',
    data: data,
  })
}

// 包裹拉单
export function orderNotice(data) {
  return request({
    url: 'index/Api/notice',
    method: 'post',
    data: data,
  })
}

// 包裹删除
export function orderDel(data) {
  return request({
    url: 'admin/order/del',
    method: 'post',
    data: data,
  })
}

// 中到韩包裹删除
export function orderDelZdh(data) {
  return request({
    url: 'kor_admin/order/del',
    method: 'post',
    data: data,
  })
}
// 包裹拉单验证
export function pullingCheck(data) {
  return request({
    url: 'admin/Order/pullingCheck',
    method: 'post',
    data: data,
  })
}
// 包裹导出
export function oexcelOut(data) {
  return request({
    url: 'admin/Order/excel_out',
    method: 'post',
    data: data,
  })
}

//海运包裹导出
export function seaExcelOut(data) {
  return request({
    url: 'kor_admin/Order/sea_excel_out',
    method: 'post',
    data: data,
  })
}

//空运包裹导出
export function airExcelOut(data) {
  return request({
    url: 'kor_admin/Order/air_excel_out',
    method: 'post',
    data: data,
  })
}

//ESM海运包裹导出
export function esmSeaExcelOut(data) {
  return request({
    url: 'kor_admin/Order/ems_sea_excel_out',
    method: 'post',
    data: data,
  })
}

//ESM空运包裹导出
export function esmAirExcelOut(data) {
  return request({
    url: 'kor_admin/Order/ems_air_excel_out',
    method: 'post',
    data: data,
  })
}

//包裹录入
export function excelStatus(data) {
  return request({
    url: 'kor_admin/Order/excel_status',
    method: 'post',
    data: data,
  })
}

//包裹导入
export function uploadFileZdh(data) {
  return request({
    url: 'kor_admin/Order/import_excel',
    method: 'post',
    data: data,
  })
}
// 中到韩包裹待认领列表
export function orderClaimListZdh(data) {
  return request({
    url: 'kor_admin/Order/ClaimList',
    method: 'post',
    data: data,
  })
}

// 增值列表(不带分页)
export function serviceIndex(data) {
  return request({
    url: 'index/index/service',
    method: 'post',
    data: data,
  })
}

// 增值列表
export function serviceList(data) {
  return request({
    url: 'admin/service/list',
    method: 'post',
    data: data,
  })
}

// 增值详情
export function serviceDetail(data) {
  return request({
    url: 'admin/service/detail',
    method: 'post',
    data: data,
  })
}
// 增值添加
export function serviceAdd(data) {
  return request({
    url: 'admin/service/add',
    method: 'post',
    data: data,
  })
}

// 增值修改
export function serviceUpdate(data) {
  return request({
    url: 'admin/service/update',
    method: 'post',
    data: data,
  })
}

// 增值删除
export function serviceDel(data) {
  return request({
    url: 'admin/service/del',
    method: 'post',
    data: data,
  })
}

// 支付列表
export function payList(data) {
  return request({
    url: 'admin/pay/list',
    method: 'post',
    data: data,
  })
}

// 中到韩支付列表
export function payListZdh(data) {
  return request({
    url: 'kor_admin/pay/list',
    method: 'post',
    data: data,
  })
}

// 支付导出
export function oexcelOutPay(data) {
  return request({
    url: 'admin/Pay/excel_out',
    method: 'post',
    data: data,
  })
}

// 中到韩支付导出
export function oexcelOutPayZdh(data) {
  return request({
    url: 'kor_admin/Pay/excel_out',
    method: 'post',
    data: data,
  })
}

//商品订单列表
export function goodsOrderList(data) {
  var goods_list_time = Math.floor(Date.now() / 1000)
  if (data.gtype == 1) {
    // 补价商品
    localStorage.setItem('reshoot_goods_time', goods_list_time)
  }
  if (data.gtype == 2) {
    // 代买商品列表
    localStorage.setItem('goods_list_time', goods_list_time)
  }

  return request({
    url: 'admin/GoodsOrder/list',
    method: 'post',
    data: data,
  })
}

//商品订单详情
export function goodsOrderDetail(data) {
  return request({
    url: 'admin/GoodsOrder/detail',
    method: 'post',
    data: data,
  })
}

//商品订单列表删除
export function goodsOrderDel(data) {
  return request({
    url: 'admin/GoodsOrder/del',
    method: 'post',
    data: data,
  })
}

//商品订单列表状态修改
export function goodsOrderStatus(data) {
  return request({
    url: 'admin/GoodsOrder/status',
    method: 'post',
    data: data,
  })
}

//支付退款
export function orderRefundRefund(data) {
  return request({
    url: 'admin/OrderRefund/refund',
    method: 'post',
    data: data,
  })
}

//代买代付列表添加
export function buyListAdd(data) {
  return request({
    url: 'admin/Buy/add',
    method: 'post',
    data: data,
  })
}

//代买代付列表
export function buyList(data) {
  var buy_list_time = Math.floor(Date.now() / 1000)
  if (data.type == 1) {
    localStorage.setItem('buy_list_time_k', buy_list_time)
  }
  if (data.type == 2) {
    localStorage.setItem('buy_list_time_J', buy_list_time)
  }
  if (data.type2 == 2) {
    localStorage.setItem('buy_list_time_D', buy_list_time)
  }
  return request({
    url: 'admin/Buy/list',
    method: 'post',
    data: data,
  })
}
//代买代付详情
export function buyDetail(data) {
  return request({
    url: 'admin/Buy/detail',
    method: 'post',
    data: data,
  })
}
//代买代付删除
export function buyDel(data) {
  return request({
    url: 'admin/Buy/del',
    method: 'post',
    data: data,
  })
}
//代买代付修改状态
export function buyStatus(data) {
  return request({
    url: 'admin/Buy/status',
    method: 'post',
    data: data,
  })
}
// 代买代付修改
export function buyUpdate(data) {
  return request({
    url: 'admin/Buy/update',
    method: 'post',
    data: data,
  })
}

//代买代付同步包裹预报
export function buyOrderAdd(data) {
  return request({
    url: 'admin/Buy/order_add',
    method: 'post',
    data: data,
  })
}

//上传文件
export function uploadFile(data) {
  return request({
    url: 'admin/Order/import_excel',
    method: 'post',
    data: data,
  })
}

// 待认领列表
export function Order_ClaimList(data) {
  return request({
    url: '/admin/Order/ClaimList',
    method: 'post',
    data: data,
  })
}
// 获取品牌名称
export function getBrandList(data) {
  return request({
    url: '/index/index/brand',
    method: 'post',
    data: data,
  })
}

// 获取类型名称
export function getBrandTypesList(data) {
  return request({
    url: '/index/index/brand_types',
    method: 'post',
    data: data,
  })
}

export function goods_status(data) {
  return request({
    url: 'admin/goods/status',
    method: 'post',
    data: data,
  })
}
// 轮训获取新订单数量接口
export function Index_order_num(data) {
  return request({
    url: 'admin/Index/order_num',
    method: 'post',
    data: data,
  })
}
// 商城链接列表
export function Link_list(data) {
  return request({
    url: 'admin/Link/list',
    method: 'post',
    data: data,
  })
}
// 添加商城链接
export function Link_add(data) {
  return request({
    url: '/admin/Link/add',
    method: 'post',
    data: data,
  })
}
// 修改商城链接
export function Link_update(data) {
  return request({
    url: 'admin/Link/update',
    method: 'post',
    data: data,
  })
}
// 删除商城链接
export function Link_del(data) {
  return request({
    url: 'admin/Link/del',
    method: 'post',
    data: data,
  })
}
// 新闻推荐
export function News_status(data) {
  return request({
    url: 'admin/News/status',
    method: 'post',
    data: data,
  })
}
// 管理员列表
export function user_list(data) {
  return request({
    url: 'admin/user/list',
    method: 'post',
    data: data,
  })
}
// 添加管理员
export function user_add(data) {
  return request({
    url: '/admin/user/add',
    method: 'post',
    data: data,
  })
}
// 管理员详情

export function user_detail(data) {
  return request({
    url: 'admin/user/detail_new',
    method: 'post',
    data: data,
  })
}
// 管理员修改
export function user_update(data) {
  return request({
    url: 'admin/user/update',
    method: 'post',
    data: data,
  })
}
// 用户组列表
export function AdminGroup_list(data) {
  return request({
    url: 'admin/AdminGroup/list',
    method: 'post',
    data: data,
  })
}
// 后台侧边栏列表

export function Auth_list(data) {
  return request({
    url: 'admin/index/sidebar',
    method: 'post',
    data: data,
  })
}
//侧边栏添加
export function Auth_Add(data) {
  return request({
    url: 'admin/Auth/add',
    method: 'post',
    data: data,
  })
}
// 侧边栏详情
export function Auth_detail(data) {
  return request({
    url: 'admin/Auth/detail',
    method: 'post',
    data: data,
  })
}
// 侧边栏 修改
export function Auth_update(data) {
  return request({
    url: 'admin/Auth/update',
    method: 'post',
    data: data,
  })
}
// 权限组详情
export function AdminGroup_detail(data) {
  return request({
    url: 'admin/AdminGroup/detail',
    method: 'post',
    data: data,
  })
}
// 权限组添加
export function AdminGroup_add(data) {
  return request({
    url: 'admin/AdminGroup/add',
    method: 'post',
    data: data,
  })
}
// 权限组修改
export function AdminGroup_update(data) {
  return request({
    url: 'admin/AdminGroup/update',
    method: 'post',
    data: data,
  })
}
// 侧边栏删除
export function Auth_del(data) {
  return request({
    url: 'admin/Auth/del',
    method: 'post',
    data: data,
  })
}
// 侧边栏删除
export function AdminGroup_del(data) {
  return request({
    url: 'admin/AdminGroup/del',
    method: 'post',
    data: data,
  })
}
// 管理与删除
export function user_del(data) {
  return request({
    url: 'admin/user/del',
    method: 'post',
    data: data,
  })
}
// 分类列表
export function GoodsCate_list(data) {
  return request({
    url: 'admin/GoodsCate/list',
    method: 'post',
    data: data,
  })
}
// 分类详情
export function GoodsCate_detail(data) {
  return request({
    url: 'admin/GoodsCate/detail',
    method: 'post',
    data: data,
  })
}
// 分类添加
export function GoodsCate_add(data) {
  return request({
    url: 'admin/GoodsCate/add',
    method: 'post',
    data: data,
  })
}
// 分类修改
export function GoodsCate_update(data) {
  return request({
    url: 'admin/GoodsCate/update',
    method: 'post',
    data: data,
  })
}
// 分类删除
export function GoodsCate_del(data) {
  return request({
    url: 'admin/GoodsCate/del',
    method: 'post',
    data: data,
  })
}
// 中到韩 批量拉单
export function KorApi_notice(data) {
  return request({
    url: 'kor_index/KorApi/notice',
    method: 'post',
    data: data,
  })
}
// 中单韩 拉单验证
export function Order_pullingCheck(data) {
  return request({
    url: 'kor_admin/Order/pullingCheck',
    method: 'post',
    data: data,
  })
}
// 获取省市区
export function index_city(data) {
  return request({
    url: '/index/index/city',
    method: 'post',
    data: data,
  })
}
export function deliver_goods(data) {
  return request({
    url: '/admin/GoodsOrder/deliver_goods',
    method: 'post',
    data: data,
  })
}
// 商品品牌
export function index_brand(data) {
  return request({
    url: '/index/index/brand',
    method: 'post',
    data: data,
  })
}
// 品牌类型
export function index_brand_types(data) {
  return request({
    url: '/index/index/brand_types',
    method: 'post',
    data: data,
  })
}



// 订单号 打印前检查
export function Order_codePrintCheck(data) {
  return request({
    url: '/admin/Order/codePrintCheck',
    method: 'post',
    data: data,
  })
}

// 订单号 打印前检查
export function OrderPic_list(data) {
  return request({
    url: 'admin/OrderPic/list',
    method: 'post',
    data: data,
  })
}

export function OrderPic_update(data) {
  return request({
    url: 'admin/OrderPic/update',
    method: 'post',
    data: data,
  })
}

export function OrderPic_del(data) {
  return request({
    url: 'admin/OrderPic/del',
    method: 'post',
    data: data,
  })
}

// 图片添加
export function KorPic_add(data) {
  return request({
    url: 'kor_admin/KorPic/add',
    method: 'post',
    data: data,
  })
}


// 图片列表
export function KorPic_list(data) {
  return request({
    url: 'kor_admin/KorPic/list',
    method: 'post',
    data: data,
  })
}

// 图片编辑
export function KorPic_update(data) {
  return request({
    url: 'kor_admin/KorPic/update',
    method: 'post',
    data: data,
  })
}

// 图片删除
export function KorPic_del(data) {
  return request({
    url: 'kor_admin/KorPic/del',
    method: 'post',
    data: data,
  })
}

// 添加国家
export function KorPic_country_add(data) {
  return request({
    url: 'kor_admin/KorPic/country_add',
    method: 'post',
    data: data,
  })
}

// 国家列表
export function KorPic_country_list(data) {
  return request({
    url: 'kor_admin/KorPic/country_list',
    method: 'post',
    data: data,
  })
}


// 编辑国家
export function KorPic_country_update(data) {
  return request({
    url: 'kor_admin/KorPic/country_update',
    method: 'post',
    data: data,
  })
}

// 删除国家

export function KorPic_country_del(data) {
  return request({
    url: 'kor_admin/KorPic/country_del',
    method: 'post',
    data: data,
  })
}


